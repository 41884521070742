// src/App.js
import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './components/contexts/AuthContext';
import { SubscriptionProvider } from './components/contexts/SubscriptionContext';
import { Toaster } from './components/ui/toaster';
import { useToast } from './components/ui/use-toast';
import { useAuth } from './components/contexts/AuthContext';
import { useSubscription } from './components/contexts/SubscriptionContext';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import { Loader2 } from "lucide-react";
import MainLayout from './components/layouts/MainLayout';

// Lazy load all page components
const HomePage = React.lazy(() => import('./components/home/HomePage'));
const VehicleCostCalculator = React.lazy(() => import('./components/vehicle/VehicleCostCalculator'));
const HouseCostCalculator = React.lazy(() => import('./components/house/HouseCostCalculator'));
const About = React.lazy(() => import('./components/pages/About'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Privacy = React.lazy(() => import('./components/pages/Privacy'));
const Terms = React.lazy(() => import('./components/pages/Terms'));
const Pricing = React.lazy(() => import('./components/pages/Pricing'));
const ManageSubscription = React.lazy(() => import('./subscription/ManageSubscription'));
const FinancialTips = React.lazy(() => import('./components/advertising/components/FinancialTips'));

// Loading component for suspense fallback
const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50">
    <div className="flex flex-col items-center gap-2">
      <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      <p className="text-sm text-gray-600">Loading...</p>
    </div>
  </div>
);

// Error Fallback component
const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50">
    <div className="max-w-md w-full mx-auto p-6 text-center">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Oops! Something went wrong
      </h2>
      <p className="text-gray-600 mb-6">
        {error.message || "We're sorry for the inconvenience. Please try refreshing the page."}
      </p>
      <div className="space-x-4">
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Refresh Page
        </button>
        <button
          onClick={resetErrorBoundary}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          Try Again
        </button>
      </div>
    </div>
  </div>
);

const AppContent = () => {
  const [netSalary, setNetSalary] = useState('');
  const [budgetPercentage, setBudgetPercentage] = useState(30);
  const { toast } = useToast();
  const { user } = useAuth();
  const { subscription } = useSubscription();

  const handleSelectCalculator = (type, salary, percentage) => {
    try {
      setNetSalary(salary);
      setBudgetPercentage(percentage);
    } catch (error) {
      console.error('Error selecting calculator:', error);
      toast({
        title: 'Error',
        description: 'Failed to update calculator settings. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleBackToHome = (updatedSalary, updatedPercentage) => {
    try {
      setNetSalary(updatedSalary);
      setBudgetPercentage(updatedPercentage);
    } catch (error) {
      console.error('Error updating home values:', error);
      toast({
        title: 'Error',
        description: 'Failed to update values. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const getPricingRoute = () => {
    if (user && subscription?.status && ['active', 'trialing'].includes(subscription.status)) {
      return <Navigate to="/manage-subscription" replace />;
    }
    return <Pricing />;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <main className="flex-grow">
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route 
                path="/" 
                element={
                  <MainLayout>
                    <HomePage 
                      onSelectCalculator={handleSelectCalculator}
                      initialNetSalary={netSalary}
                      initialBudgetPercentage={budgetPercentage}
                    />
                  </MainLayout>
                } 
              />
              
              <Route 
                path="/financial-tips/*" 
                element={
                  <MainLayout>
                    <FinancialTips />
                  </MainLayout>
                } 
              />

              <Route 
                path="/vehicle-cost-calculator" 
                element={
                  <MainLayout>
                    <VehicleCostCalculator 
                      onBack={handleBackToHome}
                      initialNetSalary={netSalary}
                      initialBudgetPercentage={budgetPercentage}
                    />
                  </MainLayout>
                } 
              />

              <Route 
                path="/house-cost-calculator" 
                element={
                  <MainLayout>
                    <HouseCostCalculator 
                      onBack={handleBackToHome}
                      initialNetSalary={netSalary}
                      initialBudgetPercentage={budgetPercentage}
                    />
                  </MainLayout>
                } 
              />

              <Route 
                path="/about" 
                element={
                  <MainLayout showAds={false}>
                    <About />
                  </MainLayout>
                } 
              />

              <Route 
                path="/contact" 
                element={
                  <MainLayout showAds={false}>
                    <Contact />
                  </MainLayout>
                } 
              />

              <Route 
                path="/privacy" 
                element={
                  <MainLayout showAds={false}>
                    <Privacy />
                  </MainLayout>
                } 
              />

              <Route 
                path="/terms" 
                element={
                  <MainLayout showAds={false}>
                    <Terms />
                  </MainLayout>
                } 
              />

              <Route 
                path="/pricing" 
                element={
                  <MainLayout showAds={false}>
                    {getPricingRoute()}
                  </MainLayout>
                } 
              />

              <Route 
                path="/manage-subscription" 
                element={
                  <MainLayout showAds={false}>
                    <ManageSubscription />
                  </MainLayout>
                } 
              />

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </main>
      </ErrorBoundary>
      <Footer />
      <Toaster />
    </div>
  );
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <Router>
          <AuthProvider>
            <SubscriptionProvider>
              <AppContent />
            </SubscriptionProvider>
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;