// src/contexts/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db, googleProvider, microsoftProvider } from '../../firebaseConfig';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [subscription, setSubscription] = useState(null);

  // Monitor online status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Auth state listener
  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          console.log('User authenticated:', user.email);
          setUser(user);
          // Fetch subscription status
          const subscriptionDoc = await getDoc(doc(db, 'subscriptions', user.uid));
          setSubscription(subscriptionDoc.exists() ? subscriptionDoc.data() : null);
        } else {
          console.log('No user authenticated');
          setUser(null);
          setSubscription(null);
        }
      } catch (err) {
        console.error('Auth state change error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleUserCreation = async (user, isRegistering, provider) => {
    if (!isOnline) {
      throw new Error('You are currently offline. Please check your internet connection and try again.');
    }

    console.log('Handling user creation/update:', { isRegistering, provider });
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const existingUser = userDoc.exists();

      // Handle registration logic
      if (isRegistering && existingUser) {
        console.log('Attempted to register existing user');
        throw new Error('An account already exists with this email. Please sign in instead.');
      }

      // Handle sign in logic
      if (!isRegistering && !existingUser) {
        console.log('Attempted to sign in non-existent user');
        throw new Error('No account found. Please register first.');
      }

      const userData = {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        lastLoginAt: serverTimestamp(),
        provider: provider,
        updatedAt: serverTimestamp()
      };

      if (!existingUser) {
        userData.createdAt = serverTimestamp();
        userData.isNewUser = true;
      }

      console.log('Saving user data:', userData);
      await setDoc(userRef, userData, { merge: true });
      console.log('User data saved successfully');

      return existingUser;
    } catch (error) {
      console.error('Error in handleUserCreation:', error);
      throw error;
    }
  };

  const signInWithGoogle = async (isRegistering = false) => {
    if (!isOnline) {
      throw new Error('You are currently offline. Please check your internet connection and try again.');
    }

    try {
      setError(null);
      console.log('Starting Google sign-in process');
      const result = await signInWithPopup(auth, googleProvider);
      console.log('Google sign-in successful');
      await handleUserCreation(result.user, isRegistering, 'google.com');
      return result.user;
    } catch (error) {
      console.error('Google sign-in error:', error);
      let errorMessage = 'Failed to sign in with Google';
      
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          errorMessage = 'Sign-in cancelled. Please try again.';
          break;
        case 'auth/account-exists-with-different-credential':
          errorMessage = 'An account already exists with the same email address but different sign-in credentials.';
          break;
        case 'auth/cancelled-popup-request':
          errorMessage = 'Multiple sign-in popups detected. Please try again.';
          break;
        case 'auth/popup-blocked':
          errorMessage = 'Sign-in popup was blocked by your browser.';
          break;
      }
      
      setError(errorMessage);
      throw error;
    }
  };

  const signInWithMicrosoft = async (isRegistering = false) => {
    if (!isOnline) {
      throw new Error('You are currently offline. Please check your internet connection and try again.');
    }

    try {
      setError(null);
      console.log('Starting Microsoft sign-in process');
      const result = await signInWithPopup(auth, microsoftProvider);
      console.log('Microsoft sign-in successful');
      await handleUserCreation(result.user, isRegistering, 'microsoft.com');
      return result.user;
    } catch (error) {
      console.error('Microsoft sign-in error:', error);
      let errorMessage = 'Failed to sign in with Microsoft';
      
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          errorMessage = 'Sign-in cancelled. Please try again.';
          break;
        case 'auth/account-exists-with-different-credential':
          errorMessage = 'An account already exists with the same email address but different sign-in credentials.';
          break;
        case 'auth/cancelled-popup-request':
          errorMessage = 'Multiple sign-in popups detected. Please try again.';
          break;
        case 'auth/popup-blocked':
          errorMessage = 'Sign-in popup was blocked by your browser.';
          break;
      }
      
      setError(errorMessage);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      console.log('Signing out user');
      await firebaseSignOut(auth);
      setUser(null);
      setSubscription(null);
      console.log('Sign out successful');
    } catch (error) {
      console.error('Sign out error:', error);
      setError('Failed to sign out. Please try again.');
      throw error;
    }
  };

  const value = {
    user,
    subscription,
    loading,
    error,
    isOnline,
    signInWithGoogle,
    signInWithMicrosoft,
    signOut,
    setError
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;